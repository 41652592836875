import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardActionArea ,TextField} from '@mui/material';
import gitlabimg from "./../../images/Gitlab.png"
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Update';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Switch from '@mui/material/Switch';
import { Space,Alert} from 'antd';
import {InputAdornment} from '@mui/material';
const ProjectsList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  
  const [projects, setProjects] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [deleted, setDeleted] = useState(false);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filtered =() => {
    const results = projects.filter(project =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())|| project.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setProjects(results);
    
  };
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await axios.get(baseURL+'/api/gitlab/', {
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    if (searchTerm === '') {
      fetchProjects();
    } else {
      filtered();
    }
  }, [searchTerm,deleted]);
  const handleCardClick = (project) => {
    setSelectedProject(project === selectedProject ? null : project); // Toggle selected job
  };
  const OnSwitchChange= async (project,value)=>{
    const projectData = {
      
      project_id: project.project_id,
      
    };
    if (value===true){
    try {
      
      const token = localStorage.getItem('token'); // Retrieve the token from local storage

      const response = await axios.post(baseURL+'/api/gitlab/webhook-set/', projectData,{
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
            // Update the project's webhook state locally
            setProjects((prevProjects) =>
              prevProjects.map((p) =>
                p.id === project.id ? { ...p, webhook: value } : p
              )
            );
    } catch (error) {
      setIsSuccess(false)
      console.error('Error updating webhook state :', error);
    }}else{
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await axios.post(baseURL+'/api/gitlab/webhook-del/', projectData,{
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });
        setIsSuccess(true)
              // Update the project's webhook state locally
      setProjects((prevProjects) =>
        prevProjects.map((p) =>
          p.id === project.id ? { ...p, webhook: value } : p
        )
      );
      } catch (error) {
        setIsSuccess(false)
        console.error('Error updating webhook state :', error);}

    }}
    const handleDelete= async (id)=>{
      if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
  
        const response = await axios.delete(baseURL+'/api/gitlab/'+id+'/',{
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });

        setDeleted(true);

      } catch (error) {
        
        console.error('Error updating webhook state :', error);
      }
    }
  };

return (
    <div style={{marginLeft: '0%', marginTop:"120px"}}>
            <h1>Projects : </h1>
            { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '80%',
      }}
    >
         <Alert
        message="Success "
        description="Gitlab Project successfully updated."
        type="success"
        showIcon
        closable
      />
      </Space>)}
           
      <TextField
        label="Search Projects"
        variant="outlined"
        value={searchTerm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ManageSearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px', width: '30%' ,marginTop:'50px'}}
      />
    
    <div style={{display:'flex',justifyContent: 'center',gap: '30px', marginTop: '50px',grid:'initial',flexWrap:'wrap'}}>
      
      {projects.map((project) => (
        <Card sx={{ maxWidth: 345, marginBottom: 2 }} key={project.id} 
        onClick={() => handleCardClick(project)}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="190"
              image={project.image || gitlabimg}
              alt={project.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              
            <Link to={`/gitlab/${project.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>{project.name}</Link>
          

            
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {project.username || ""}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                {project.project_id || "No description available."}
              </Typography>
              <Typography variant="body2" color="text.secondary">   
                        <Switch
            checked={project.webhook}
            onChange={() => OnSwitchChange(project,!project.webhook)}
            name="webhook"
            color="primary"
            title="webhook"
          />
              </Typography>
                              {/* Render if is selected */}
                              {selectedProject && selectedProject.id === project.id && (
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                    { project.webhook|| "No details."}
    <Stack direction="row" spacing={7}>
      <Button variant="outlined" startIcon={<DeleteIcon />}  onClick={() => handleDelete(project.id)}>
        Delete
      </Button>
      <Button variant="contained" endIcon={<UpdateIcon />}>
      <Link to={`/gitlab/${project.id}/update`}style={{ textDecoration: 'none', color: 'inherit' }}>update</Link>
      </Button>
    </Stack>
                  </Typography>
                )}
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
    </div>
  );
};

export default ProjectsList;