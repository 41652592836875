import './App.css';
import ProjectsList from './components/Github/ProjectsList';
import ProjectDetails from './components/Github/ProjectDetails';
import PipelineDetails from './components/Github/PipelineDetails';
import AddProject from './components/Github/AddProject';
import UpdateProject from './components/Github/UpdateProject';
import Dashboard_github from './components/Github/Dashboard';

import ProjectsList_jenkins from './components/Jenkins/ProjectsList';
import AddPipeline_jenkins from './components/Jenkins/AddPipeline';
import PipelineDetails_jenkins from './components/Jenkins/PipelineDetails';
import AddProject_jenkins from './components/Jenkins/AddProject';
import UpdateProject_jenkins from './components/Jenkins/UpdateProject';
import Dashboard_jenkins from './components/Jenkins/Dashboard'; 

import ProjectsList_gitlab from './components/Gitlab/ProjectsList';
import PipelineDetails_gitlab from './components/Gitlab/PipelineDetails';
import AddProject_gitlab from './components/Gitlab/AddProject';
import UpdateProject_gitlab from './components/Gitlab/UpdateProject';
import ProjectDetails_gitlab from './components/Gitlab/ProjectDetails';
import Dashboard_gitlab from './components/Gitlab/Dashboard'; 

import OrganizationsList_azure from './components/Azure/OrganizationsList';
import ProjectsList_azure from './components/Azure/ProjectsList';
import PipelineDetails_azure from './components/Azure/PipelineDetails';
import AddOrganization_azure from './components/Azure/AddOrganization';
import UpdateOrganization_azure from './components/Azure/UpdateOrganization';
import ProjectDetails_azure from './components/Azure/ProjectDetails';
import AddProject_azure from './components/Azure/AddProject';
import Dashboard_azure from './components/Azure/Dashboard'; 

import Dashboard from './components/Dashboard';
import { BrowserRouter as Router , Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import RegisterPart3 from './components/RegisterPart3';
import Invitation from './components/Invitation';
import Password from './components/Password';
import PasswordReset from './components/PasswordReset';
import Login from './components/Login';
import Admin from './layouts/Admin';
import PrivateRoute from './utils/PrivateRoute';
import Profile from './pages/Profile';
import User from './pages/Team';
import Solution from './components/Solution'

function App() {
 
  return (
   
    <div className="App">
    
    <Router>
 
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route exact path="/register_verif" element={<RegisterPart3 />} />
        <Route exact path="/accept_invitation/:string" element={<Invitation />} />
        <Route exact path="/invitation_password" element={<Password />} />
        <Route exact path="/password/reset/:uid/:token" element={<PasswordReset />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/"
            element={
            <PrivateRoute><Admin />    </PrivateRoute>
          }>


<Route path="/" element={<Dashboard />} />
        <Route path="/dash" element={<Dashboard />} />

        {/* GitHub routes */}
        <Route exact path="/github" element={<ProjectsList />} />
        <Route exact path="/github/dash" element={<Dashboard_github />} />
        <Route exact path="/github/add" element={<AddProject />} />
        <Route path="/github/:projectId" element={<ProjectDetails />} />
        <Route path="/github/:projectId/update" element={<UpdateProject />} />
        <Route path="/github/:projectId/:pipelineId" element={<PipelineDetails />} />
                {/* Gitlab routes */}
                <Route exact path="/gitlab" element={<ProjectsList_gitlab />} />
        <Route exact path="/gitlab/dash" element={<Dashboard_gitlab />} />
        <Route exact path="/gitlab/add" element={<AddProject_gitlab />} />
        <Route path="/gitlab/:projectId" element={<ProjectDetails_gitlab />} />
        <Route path="/gitlab/:projectId/update" element={<UpdateProject_gitlab />} />
        <Route path="/gitlab/:projectId/:pipelineId" element={<PipelineDetails_gitlab />} />
                        {/* Azure routes */}
                <Route exact path="/azure" element={<OrganizationsList_azure />} />
                
                <Route exact path="/azure/:organizationId" element={<ProjectsList_azure />} />
                <Route exact path="/azure/:organizationId/add" element={<AddProject_azure />} />
        <Route exact path="/azure/dash" element={<Dashboard_azure />} />
        <Route exact path="/azure/add" element={<AddOrganization_azure />} />
        <Route path="/azure/:organizationId/:projectId" element={<ProjectDetails_azure />} />
        <Route path="/azure/:organizationId/update" element={<UpdateOrganization_azure />} />
        <Route path="/azure/:organizationId/:projectId/:pipelineId" element={<PipelineDetails_azure />} />
               {/* Jenkins routes */}
               <Route exact path="/jenkins" element={<ProjectsList_jenkins />} />
               <Route exact path="/jenkins/dash" element={<Dashboard_jenkins />} />
        <Route exact path="/jenkins/add" element={<AddProject_jenkins />} />
        <Route path="/jenkins/:projectId/add" element={<AddPipeline_jenkins />} />
        <Route path="/jenkins/:projectId/update" element={<UpdateProject_jenkins />} />
        <Route path="/jenkins/:projectId" element={<PipelineDetails_jenkins />} />
         {/*  routes */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/users" element={<User />} />
        <Route path="/api/ia" element={<Solution />} />
        </Route>
      </Routes>
     
  </Router>
  </div>
  );
}

export default App;
