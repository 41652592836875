import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import gitlabimg from "./../../images/Gitlab.png"
import  { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import axios from 'axios';

const ProjectDetails = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [runActiveState, setRunActiveState] = useState({});
  const [project, setProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const { projectId } = useParams();
  const handelRun=(e,pipeline_id)=>{
    e.preventDefault();
  const token = localStorage.getItem('token'); // Retrieve the token from local storage

  const response =  axios.post(baseURL+'/api/gitlab/'+projectId+'/pipelines/'+pipeline_id+'/run/', {},{
    headers: {
      Authorization: `Token ${token}`, // Add the token to the request headers
    },
  });
  setRunActiveState((prevState) => ({
    ...prevState,
    [pipeline_id]: true,
  }));
};
const handleCancel=(e,pipeline_id)=>{
  e.preventDefault();
const token = localStorage.getItem('token'); // Retrieve the token from local storage

const response =  axios.post(baseURL+'/api/gitlab/'+projectId+'/pipelines/'+pipeline_id+'/cancel/', {},{
  headers: {
    Authorization: `Token ${token}`, // Add the token to the request headers
  },
});
setRunActiveState((prevState) => ({
  ...prevState,
  [pipeline_id]: false,
}));
};
  useEffect(() => {
    const fetchProject = async () => {
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await axios.get(baseURL+'/api/gitlab/'+projectId+'/pipelines/', {
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });
        setProject(response.data);
        console.log(project);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProject();
  }, []);
console.log("data",project)
  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{marginLeft: '0%', marginTop:"120px"}}>
      <h2>Pipelines :</h2>
      <hr style={{ marginTop: " 10% ",width:'50%',maxWidth:'50%',margin: 'auto'}}></hr>
      <div style={{display:'flex', justifyContent: 'center', gap: '30px', marginTop: '50px', grid:'initial', flexWrap:'wrap'}}>
        {project.map((pipeline) => (
          <Card sx={{ maxWidth: 345, marginBottom: 2 }} key={pipeline.id}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="190"
                image={gitlabimg} // Replace with actual image URL if available
                alt={pipeline.pipeline_id}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  <Link to={`/gitlab/${projectId}/${pipeline.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>{pipeline.pipeline_id}</Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {pipeline.branch || "No description available."}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {pipeline.status || "No description available."}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: 'white', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                   
                   <Stack direction="row" spacing={4}>
                     <Button sx={{ padding: '10px 20px', fontSize: '12px' ,color:'white'}} variant="outlined" startIcon={<HighlightOffOutlinedIcon />}  onClick={(e)=> handleCancel(e,pipeline.id)}>
                       Cancel
                     </Button>
                     <Button 
                     sx={{       
            padding: '10px 20px',
            fontSize: '12px',
            backgroundColor: runActiveState[pipeline.id]   ? 'green' : 'transparent',
            color:'white',
            '&:hover': {
              backgroundColor:  runActiveState[pipeline.id]  ? 'darkgreen' : 'rgba(0, 0, 0, 0.04)',
            },
          }} variant="outlined" 
          startIcon={<PlayCircleOutlineOutlinedIcon />} 
           onClick={(e)=>handelRun(e,pipeline.id)}>
                       Run
                     </Button>

                   </Stack>
                                 </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetails;
