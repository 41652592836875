import React from 'react';

import Dashboard_github from './Github/Dashboard';
import Dashboard_jenkins from './Jenkins/Dashboard';
import Dashboard_gitlab from './Gitlab/Dashboard';
import Dashboard_azure from './Azure/Dashboard';

const Dashboard = () => {
  return (
    <>
    <Dashboard_github/>
    <Dashboard_jenkins/>
    <Dashboard_gitlab/>
    <Dashboard_azure/>
    </>
  );
}

export default Dashboard;