import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import githubimg from './../../images/github.png';
import successImg from './../../images/success.jpg'; 
import failureImg from './../../images/failed-image.png';
import axios from 'axios';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Button from '@mui/material/Button';
import Solution from '../Solution';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
const PipelineDetails = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [pipeline, setPipeline] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const { projectId, pipelineId } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        
        const token = localStorage.getItem('token');
        const response = await axios.get(baseURL+'/api/github/'+projectId+'/workflows/'+pipelineId+'/jobs/', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setPipeline(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProject();
  }, []);
  const formatLogs = (logs) => {
    if (!logs) return "No logs available.";
    const lines = logs.split('\n');
    const first5Lines = lines.slice(0, 5).join('\n');
    const last10Lines = lines.slice(-10).join('\n');
    return `${first5Lines}\n...\n${last10Lines}`;
  };
  const handleCardClick = (job) => {
    setSelectedJob(job === selectedJob ? null : job); // Toggle selected job
  };
  const handle_recommendation = (errors) => {
    localStorage.setItem('errors',errors);
  };
  return (
    <div style={{ marginLeft: '0%', marginTop: "120px" }}>
      <h2  >Jobs :</h2>
      <hr style={{ marginTop: " 10% ",width:'50%',maxWidth:'50%',margin: 'auto'}}></hr>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '50px', grid: 'initial', flexWrap: 'wrap' }}>
        {pipeline.map((job) => (
          <Card
            sx={{ maxWidth: 345, marginBottom: 2 ,minWidth:200}}
            key={job.id}
            onClick={() => handleCardClick(job)}
            style={{ cursor: 'pointer', backgroundColor: 'inherit' }}
          >
            <CardActionArea>
              <img
                className="img"
                height="190"
                src={job.status === 'success' ? successImg : job.status === 'failure' ? failureImg : githubimg}
                alt={job.name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div"  style={{ display: 'flex', alignItems: 'center' }}>
                  {<AssignmentOutlinedIcon style={{ marginRight: '5px' }}/> } { job.name}
                </Typography>
                <Typography variant="body2" color="text.secondary"  style={{ display: 'flex', alignItems: 'center' }}>
                {<PersonOutlineOutlinedIcon style={{ marginRight: '5px' }}/> } {job.owner }

                </Typography>  
                <Typography variant="body2" color="text.secondary"  style={{ display: 'flex', alignItems: 'center' }}>
                {<FeedbackOutlinedIcon style={{ marginRight: '5px' }}/> } {job.status || "No description available."}
                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ display: 'flex', alignItems: 'center' }}>
  <AccessTimeOutlinedIcon style={{ marginRight: '5px' }} /> {job.time || "No description available."}
</Typography>

                {/* Render logs only if the current job is selected */}
                {selectedJob && selectedJob.id === job.id && (
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' }}>
                    {formatLogs(job.log )|| "No logs available."}
                    <hr></hr>
                    {job.errors || "No errors or warnings"}
                    
                  </Typography>

                )}
                                              {job.errors && (
                                <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                                  
                  <Stack direction="row" spacing={7}  style={{alignItems:'center' ,justifyContent: 'center' }}>
         
                    <Button variant="contained" endIcon={<TipsAndUpdatesIcon />} onClick={handle_recommendation(job.errors)}>
                      
                    <Link to={`/api/ia`}style={{ textDecoration: 'none', color: 'inherit' }}>Get Recommendation</Link>
                    </Button>
                  </Stack>
                                </Typography>
                              )}
              </CardContent>
            </CardActionArea>

          </Card>
        ))}
      </div>
    </div>
  );
};

export default PipelineDetails;
