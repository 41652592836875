import React, { useState, useEffect } from "react";
import "../Styles/Register.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useParams } from "react-router-dom";
const PasswordReset = (props) => {
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [password, setPassword] = useState("");
  let { uid,token } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password:password,
    password_confirmation:password_confirmation,
});
  const submit = (e) => {
    setLoading(true);
    setError("");

    e.preventDefault();
    const formData = {
      new_password: password,
      new_password_confirm: password_confirmation,
    };
    AuthService.password_set(uid,token,formData)
      .then((response) => {
        console.log(response.data);
        window.alert("Your password has been set successfully. ")
        navigate("/login");
      })

      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message || "Wrong password");
        } else {
          setError("Wrong password");
        }
        setLoading(false);
      });
  };




  return (
    <Container fluid className="container-register">
      <Row className="card-login">
      <div className="d-flex flex-row align-items-center justify-content-between" style={{ backgroundColor: "#E9EDFC" }}>
        <Col
          lg={6}
          md={6}
          xs={12}
          className="d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "white"  }}
        >
          <div className="frame vh-100" style={{ backgroundColor: "white" }}>
            <div className="welcome-back">
              <div className="text-wrapper">Welcome back</div>
              <p className="div">Welcome back! Please Set a password</p>
            </div>
            <Form.Group className="form-field mb-1">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  placeholder="Enter your password"
                />
                
              </Form.Group>
         

            

              <div className="div-4">
              <Form.Group className="form-field">
                <Form.Label className="form-label">Password confirmation</Form.Label>
                <Form.Control
                  onChange={(e) => setPassword_confirmation(e.target.value)}
                  type="password"
                  id="password_confirmation"
                  className="form-control"
                  value={password_confirmation}
                  placeholder="Rewrite your password"
                />
                
              </Form.Group>
              <div className="mt-3 text-center">
                {error && <p className="text-danger">{error}</p>}
              </div>
           

            <div className="div-5">
              <Button
                className="btn btn-primary"
                type="submit"
                onClick={submit}
              >
                <div className="signin-text"> Set Password</div>
              </Button>

            
            </div>


          </div>
          </div>
        </Col>
    
        <Col lg={6} xs={12} md={6} className="image-container" style={{ backgroundColor: "#E9EDFC" }}
        >
          <div className="splash">
            <img
              src="../assets/images/Serops-Logo.png"
              alt=""
              className="logo"
            />
            <img
              src="./assets/images/Serops-img.png"
              alt=""
              className="illustration"
            />
          </div>
        </Col>
       </div>
      </Row>
    </Container>
  );
};

export default PasswordReset;

/*

       

*/
