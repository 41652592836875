import React, { useState, useEffect } from "react";
import "../Styles/Register.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submit = (e) => {
    setLoading(true);
    setError("");

    e.preventDefault();
    AuthService.login(email, password)
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        navigate("/dash");
      })

      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message || "Wrong email or password");
        } else {
          setError("Wrong email or password");
        }
        setLoading(false);
      });
  };

  const navigate = useNavigate();
  const handleSignupClick = (event) => {
    navigate("/register");
    event.preventDefault();
  };
  const handleForgot = (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    if (!email) {
      window.alert("Please enter your email to reset password");
      setLoading(false);
      return;
    }

    AuthService.password_forget(email)
      .then((response) => {
        console.log(response.data);
        window.alert("Check your email to reset password");
        navigate("/login");
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.message || "Something went wrong");
        } else {
          setError("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Container fluid className="container-register">
      <Row className="card-login">
      <div className="d-flex flex-row align-items-center justify-content-between" style={{ backgroundColor: "#E9EDFC" }}>
        <Col
          lg={6}
          md={6}
          xs={12}
          className="d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "white"  }}
        >
          <div className="frame vh-100" style={{ backgroundColor: "white" }}>
            <div className="welcome-back">
              <div className="text-wrapper">Welcome back</div>
              <p className="div">Welcome back! Please enter your details</p>
            </div>

            <Form.Group className="form-field mb-1">
              <Form.Label htmlFor="email" className="form-label">
                Email
              </Form.Label>
              <Form.Control
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                type="text"
                id="email"
                placeholder="Enter your email"
              />
            </Form.Group>

            <div className="div-4">
              <Form.Group className="form-field">
                <Form.Label className="form-label">Password</Form.Label>
                <Form.Control
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter your password"
                />
                
              </Form.Group>
              <a className="forgot-password mt-1 " onClick={handleForgot}>Forgot your password</a>
              <div className="mt-3 text-center">
                {error && <p className="text-danger">{error}</p>}
              </div>
            </div>

            <div className="div-5">
              <Button
                className="btn btn-primary"
                type="submit"
                onClick={submit}
              >
                <div className="signin-text">Sign In</div>
              </Button>

              <Button className="btn-google-auth">
                <div className="d-flex align-items-center">
                  <img
                    src="../assets/images/google.png"
                    alt="google"
                    className="img-google"
                  />
                  <div className="signup-google-text ms-2">
                    Sign up with Google
                  </div>
                </div>
              </Button>
            </div>

            <p className="don-t-have-an">
              <span style={{ color: "#bac6f5" }}>
                Don't have an account?&nbsp;{" "}
                <a style={{ color: "#2b47d6" }} onClick={handleSignupClick}>
                  Sign up
                </a>
              </span>
            </p>
          </div>
        </Col>

        <Col lg={6} xs={12} md={6} className="image-container" style={{ backgroundColor: "#E9EDFC" }}
        >
          <div className="splash">
            <img
              src="../assets/images/Serops-Logo.png"
              alt=""
              className="logo"
            />
            <img
              src="./assets/images/Serops-img.png"
              alt=""
              className="illustration"
            />
          </div>
        </Col>
       </div>
      </Row>
    </Container>
  );
};

export default Login;

/*

       

*/
