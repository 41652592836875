import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CardActionArea ,TextField} from '@mui/material';
import azureimg from "./../../images/azure.png"
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import UpdateIcon from '@mui/icons-material/Update';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Switch from '@mui/material/Switch';
import { Space,Alert} from 'antd';
import {InputAdornment} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const OrganizationsList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [deleted, setDeleted] = useState(false);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const filtered =() => {
    const results = organizations.filter(organization =>
      organization.name.toLowerCase().includes(searchTerm.toLowerCase()) || organization.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setOrganizations(results);
    
  };
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await axios.get(baseURL+'/api/azure/', {
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    if (searchTerm === '') {
      fetchProjects();
    } else {
      filtered();
    }
  }, [searchTerm,deleted]);
  const handleCardClick = (organization) => {
    setSelectedOrganization(organization === selectedOrganization ? null : organization); // Toggle selected job
  };

    const handleDelete= async (id)=>{
      if (window.confirm('Are you sure you want to delete this organization?')) {
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
  
        const response = await axios.delete(baseURL+'/api/azure/'+id+'/',{
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });

        setDeleted(true);

      } catch (error) {
        
        console.error('Error deleting organization :', error);
      }
    }
  };
  const handleLinkClick = (e,name,id) => {
    e.preventDefault(); // Prevent the default link behavior
    localStorage.setItem('organizationName', name); // Store the organization name in local storage
    navigate(`/azure/${id}`)
  };
return (
    <div style={{marginLeft: '0%', marginTop:"120px"}}>
            <h1>Organizations : </h1>
            { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '80%',
      }}
    >
         <Alert
        message="Success "
        description="Gitlab Project successfully updated."
        type="success"
        showIcon
        closable
      />
      </Space>)}
           
      <TextField
        label="Search Projects"
        variant="outlined"
        value={searchTerm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ManageSearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearchChange}
        style={{ marginBottom: '20px', width: '30%' ,marginTop:'50px'}}
      />
    
    <div style={{display:'flex',justifyContent: 'center',gap: '30px', marginTop: '50px',grid:'initial',flexWrap:'wrap'}}>
      
      {organizations.map((organization) => (
        <Card sx={{ maxWidth: 345, marginBottom: 2 }} key={organization.id} 
        onClick={() => handleCardClick(organization)}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="190"
              image={organization.image || azureimg}
              alt={organization.name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              
            <Link to={`/azure/${organization.id}`} onClick={(e) => handleLinkClick(e, organization.name,organization.id)}  style={{ textDecoration: 'none', color: 'inherit' }}>{organization.name}</Link>
          

            
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {organization.username || "No description available."}
              </Typography>

                              {/* Render if is selected */}
                              {selectedOrganization && selectedOrganization.id === organization.id && (
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                   
    <Stack direction="row" spacing={2}>
      <Button sx={{ padding: '10px 20px', fontSize: '12px' }} variant="outlined" startIcon={<DeleteIcon />}  onClick={() => handleDelete(organization.id)}>
        Delete
      </Button>
      <Button  sx={{ padding: '10px 20px', fontSize: '12px' }} variant="contained" endIcon={<UpdateIcon />}>
      <Link to={`/azure/${organization.id}/update`}style={{ textDecoration: 'none', color: 'inherit' }}>update</Link>
      </Button>
      <Button sx={{ padding: '10px 20px', fontSize: '12px' }} variant="contained" endIcon={<AddIcon />}>
      <Link to={`/azure/${organization.id}/add`}style={{ textDecoration: 'none', color: 'inherit' }}>Add Project </Link>
      </Button>
    </Stack>
                  </Typography>
                )}
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </div>
    </div>
  );
};

export default OrganizationsList;