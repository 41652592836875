import React, { useState, useEffect } from 'react';
import Stepper from 'react-stepper-horizontal';
import RegisterPart1 from './RegisterPart1';
import RegisterPart2 from './RegisterPart2';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Register() {
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState({
    user: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
    },
    organisation: {
      name: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const steps = [{ title: 'Step 1' }, { title: 'Step 2' }];

  useEffect(() => {
    if (AuthService.getUser()) {
      navigate('/dash');
    }
  }, [navigate]);

  const handleNext = () => {
    if (currentPage < steps.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    AuthService.register(formData)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem('token', JSON.stringify(response.data.token));
        }
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }
        navigate('/register_verif');
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  const handleBack = () => {
    setCurrentPage(currentPage - 1);
  };

  const PageDisplay = () => {
    if (currentPage === 0) {
      return <RegisterPart1 formData={formData} setFormData={setFormData} />;
    } else if (currentPage === 1) {
      return <RegisterPart2 formData={formData} setFormData={setFormData} />;
    }
  };

  return (
    <Container fluid className="container-register">
      <Row className="card-login">
      <div className="d-flex flex-row align-items-center justify-content-between">
        <Col lg={6} md={6} xs={12} className='form' style={{backgroundColor :"white"}} >
          
          <div className="frame vh-100 ">

            

            <div className="welcome-back">
            <div className="text-wrapper">{currentPage === 0 ? 'Sign up' : currentPage === 1 ? 'Create your password' : ''}</div>
            
          </div>
            <Form autoComplete="off" className='frame' onSubmit={handleSubmit}>
              

              {PageDisplay()}

              <div className="div-5">
                {currentPage > 0 && (
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleBack}
                  >
                    <div className="signin-text">Back</div>
                  </Button>
                )}
                <Button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleNext}
                >
                  <div className="signin-text"> {currentPage === steps.length - 1 ? 'Sign up' : 'Continue'} </div>
                  
                </Button>
              </div>
            </Form>
          </div>
        </Col>


        <Col lg={6} md={6} xs={12} className="image-container" style={{ background: "E9EDFC" }}>
          <div className="splash">
            <img
              src="../assets/images/Serops-Logo.png"
              alt=""
              className="logo"
            />
            <img
              src="./assets/images/Serops-img.png"
              alt=""
              className="illustration"
            />
          </div>
        </Col>
          </div>
      </Row>
    </Container>
  );
}

export default Register;
