import React, { useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { Space,Alert} from 'antd';
const UpdateProject = ( ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [gitlab_url, setGitlab_url] = useState('');
  const [project_id, setProject_id] = useState('');
  const [access_token, setAccess_token] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { projectId } = useParams();
  const getProjectData = async (token) => {

    try {
      // Add new project
      const response  = await axios.get(baseURL+'/api/gitlab/'+projectId+'/', {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setAccess_token(response.data.access_token);
      setGitlab_url(response.data.gitlab_url);
      setProject_id(response.data.project_id);
  
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
  getProjectData(token);
  },[]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const projectData = {
      gitlab_url: gitlab_url,
      project_id: project_id,
      access_token: access_token,
    };

    try {
      // Add new project
      await axios.put(baseURL+'/api/gitlab/'+projectId+'/', projectData, {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
      clearForm();
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const clearForm = () => {
    setGitlab_url('');
    setProject_id('');
    setAccess_token('');
  };

  return (
    <div style={{ marginLeft: '0px', marginTop: '50px' ,margin:"0 auto", width:"50%"}}>
      <h2>Update Project: </h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
         <Alert
        message="Success "
        description="Gitlab Project successfully updated."
        type="success"
        showIcon
        closable
      />
      </Space>)


      }
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' ,marginTop:"50px"}}>
        <TextField
          label="Gitlab url"
          variant="outlined"
          value={gitlab_url}
          onChange={(e) => setGitlab_url(e.target.value)}
          required
        />
        <TextField
          label="Project_id"
          variant="outlined"
          value={project_id}
          onChange={(e) => setProject_id(e.target.value)}
          required
        />
        <TextField
          label="Access Token "
          variant="outlined"
          value={access_token}
          onChange={(e) => setAccess_token(e.target.value)}
        />
        <div style={{width:'150px',marginTop:"30px"}}>
        <Button type="submit" variant="contained" color="primary" width="50px" >
          Update Project
        </Button>
        </div>
      </form>

    </div>
  );
};

export default UpdateProject;
