import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import {  Space,Alert} from 'antd';

const AddProject = ( ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [access_token, setAccess_token] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const organizationData = {
      name: name,
      username: username,
      access_token: access_token,
    };

    try {
      // Add new project
      await axios.post(baseURL+'/api/azure/', organizationData, {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
      clearForm();
    } catch (error) {
      console.error('Error adding organization:', error);
    }
  };

  const clearForm = () => {
    setName('');
    setUsername('');
    setAccess_token('');
  };

  return (
    <div style={{ marginLeft: '0%', marginTop: '50px' , margin:"0 auto", width:"50%"}}>
      <h2>Add New Organization: </h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
         <Alert
        message="Success "
        description="Azure Organization successfully added."
        type="success"
        showIcon
        closable
      />
      </Space>)


      }
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' ,marginTop:"50px"}}>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          label="Access Token "
          variant="outlined"
          value={access_token}
          onChange={(e) => setAccess_token(e.target.value)}
        />
        <div style={{width:'150px',marginTop:"30px"}}>
        <Button type="submit" variant="contained" color="primary" width="150px" >
          Add Organization Azure 
        </Button>
        </div>
      </form>

    </div>
  );
};

export default AddProject;
