import axios from "axios";
const AUTH_HOST= process.env.REACT_APP_BASE_URL;
const AuthService = {};

AuthService.login = (email, password) => {
        return axios
            .post(AUTH_HOST + "/auth/login", {
                email: email,
                password: password
            });
    }
    
AuthService.register = (data) => {
        return axios.post(AUTH_HOST + "/auth/register", data);
    };

    AuthService.verify=(token)=>{

        return axios.get(AUTH_HOST+"/auth/activate/",token)
    };
    AuthService.accept_invitation=(string)=>{

        return axios.get(AUTH_HOST+"/auth/accept/invitation/"+string )
    };
    AuthService.password_invitation=(data)=>{
        
        return axios.post(AUTH_HOST+"/auth/accept/password",data )
    };
    AuthService.password_forget=(email)=>{

        return axios.post(AUTH_HOST+"/auth/password/forget",{email:email} )
    };
    AuthService.password_set=(uid,token,data)=>{
        
        return axios.post(`${AUTH_HOST}/auth/password/set/${uid}/${token}`, data);
    };
    AuthService.logout = async () => {
        try {
          const token = localStorage.getItem('token');
          
          const response = await axios.post(
            AUTH_HOST + '/auth/logout',
            {},
            {
                headers: {
                    Authorization: `Token ${token}`
                }
            }
        );
          // Handle successful logout here (optional)
          return response;
        } catch (error) {
          console.error('Logout error:', error);
          // Handle logout error gracefully (e.g., notify user)
        }
      };


    
      
      
AuthService.getUser = () =>{
        let user = localStorage.getItem('user');
        if (user) {
            return JSON.parse(user);
        } else {
            return null;
        }
    };
        AuthService.setUser = (data) =>{
            let user = localStorage.setItem('user',data);
            if (user) {
                return JSON.parse(user);
            } else {
                return null;
            }
   
};
AuthService.authHeader = () =>{
    
        let token = localStorage.getItem('token');
        if (token) {
            return { headers: {
                    Authorization: "Token "+token
                }
            };
        }
        else {
            window.location.href = "/login";
        }

    

};

AuthService.update = (user) => {
    return axios.put(AUTH_HOST + "/users/" + user._id,user,AuthService.authHeader());
};
AuthService.getOne = (id) => {
    console.log(id)
    return axios.get(AUTH_HOST + "/users/" + id,AuthService.authHeader());
};
AuthService.resendVerify = () => {
    return axios
        .get(AUTH_HOST + "auth/resend");
};


AuthService.forgetPassword = (email) => {
    return axios
        .get(AUTH_HOST + "/reset/users/" + email);
};

AuthService.resetPassword = (data) => {
    return axios
        .post(AUTH_HOST + "/auth/password",data);
};



export default AuthService;
