import React from 'react';
import '../Styles/Register.css';
import { useNavigate } from 'react-router';
import AuthService from "../services/auth.service";
import { Col, Container, Row } from 'reactstrap';

function RegisterPart3() {
  const navigate = useNavigate();

  const handleResendEmail = () => {
    AuthService.resendVerify()
      .then(response => {
        console.log('Email resent successfully:', response);
      })
      .catch(error => {
        console.error('Failed to resend email:', error);
      });
  };
  

  const handleSignIn = () => {
    navigate('/login');
  };

  return (
    <div>
  <Container fluid className="container-register">
       
       <Row className="card-login ">
       <Col lg={6} md={12}  xs={12} className='form' style={{backgroundColor :"white"}} >
           <div className="padding align-items-center">
                <h2 className="text-center"></h2>
                <img src="../assets/images/subtract.png" alt="" className=" img2 justify-content-center" />
                <h3 className="text-center mt-4">Verify by Email</h3>
                <p className="lead text-center   mt-2 ">
                  Please check your email and follow the instructions to verify your account.
                  If you did not receive an email or if it has expired, you can resend one.
                </p>
                <div className="mb-2 mb-0 text-center">
                  <button className="btn mb-3 mb-0 text-center" type="button" onClick={handleResendEmail}>
                    Resend my verification email!
                  </button>
                </div>
                <div className="mb-2 mb-0 text-center">
                  <button className="btn mb-3 mb-0 text-center" type="button" onClick={handleSignIn}>
                    Sign In
                  </button>
                </div>
              </div>
            </Col>

            <Col  lg={6} md={6} xs={12} className="d-none d-md-block justify-content-center" style={{background:"#E9EDFC"}}>
              <div className="ser align-items-center">
                <img src="../assets/images/Serops-Logo.png" alt="" className="logo" />
                <img src="../assets/images/Serops-img.png" alt="" className="img-ser" style={{ maxWidth: '80%', height: 'auto' }} />
              </div>
            </Col>
          
        </Row>
      </Container>
    </div>
  );
}

export default RegisterPart3;
