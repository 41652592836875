import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Input, Container, Row, Col, Table, Form, Dropdown, DropdownMenu, DropdownItem, ModalFooter, Pagination , ButtonDropdown, DropdownToggle } from 'reactstrap';
import { Icon } from '@iconify/react';
import TeamService from '../services/team.service';
import { TablePagination, useMediaQuery } from '@mui/material';
import { WindowsFilled } from '@ant-design/icons';



export default function Team() {

  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const toggle = () => setModal(!modal);
  
  const toggleUpdate = () => setModalUpdate(!modalUpdate);
 
  const isSmallScreen = useMediaQuery('(max-width: 767px)'); 
  // Team data
  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState('');

  // Form data
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [searchQuery, setSearchQuery] = useState("");

  const [reload, setReload] = useState(false);
  // Dropdown state
  const [showDropdown, setShowDropdown] = useState(Array(teamMembers.length).fill(false));
  const [selectedMember, setSelectedMember] = useState(null);
 // Pagination state
 const [currentPage, setCurrentPage] = useState(1);
 const [totalPages, setTotalPages] = useState(1);

 // Fetch team members on component mount and whenever currentPage changes
 useEffect(() => {
  TeamService.getAll() // Pass the currentPage to the API call
    .then((res) => {
      console.log('Response data:', res.data.users);
      setTeamMembers(res.data.users);
      setTotalPages(res.data.total_pages);
    })
    .catch((err) => {
      console.error('Error:', err);
      setError(err.message);
    });
}, [reload]);



  // Add team member
  const handleInviteMember = () => {
    const data = { "user":{
      
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      role: role
    }};

    TeamService.invit(data)
      .then((response) => {
        console.log('Team member invited successfully:', response.data.user);
        setTeamMembers([...teamMembers, response.data.user]);
        toggle();
      })
      .catch((error) => {
        console.error('Error inviting team member:', error);
      });
  };
  const handleUpdate = (e) => {
    e.preventDefault(); // Prevent form submission
  
    const updatedData = {
      id: selectedMember.id,
      first_name : firstName,
      last_name : lastName,
      email: email,
      phone: phone,
    role: role };
    TeamService.update( selectedMember.id, updatedData)
      .then(response => {
        console.log('Member updated successfully:', response.data);
      setReload(true);
      toggleUpdate();
      })
      .catch(error => {
        console.error('Error updating member:', error);
      });
  };

  // Delete server
  const handleRemove = (member) => {
    TeamService.deleteOne(member.id)
      .then(response => {
        console.log('Member deleted successfully:', response.data);
        setReload(true); 
      })
      .catch(error => {
        console.error('Error deleting memeber:', error);
      })
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'role':
        setRole(value);
        break;
      default:
        break;
    }
  };
  
  const handleEdit = (member) => {
    setSelectedMember(member);
    setFirstName(member.first_name);
    setLastName(member.last_name);
    setEmail(member.email);
    setPhone(member.phone);
    setRole(member.role);
    toggleUpdate(); // Assuming toggleUpdate is the function to open the update modal
  };
  
  return (
    <div >
      <Container className='container-project  mt-5' >
        <Row className='row1' style ={{marginBottom:"50px"}}>
        <Col >
        <h1 className="title-project" style={{color : "#2B47D6", textAlign: "center" }}> Team Members </h1>
      </Col>

      
      <Col></Col>
      <Col >
            <button  className="btn-add-project" onClick={toggle} style={{margin: "50px  auto" ,color : "#2B47D6"}}>
              <i>
                <Icon icon="material-symbols:add-box" />
              </i>
              {isSmallScreen ? 'Invit' : 'Invit new Memeber'}
            </button>
            </Col>
            </Row>
            <Row >  
        
              <Col>
              
            <div className="search-container mt-2 " style={{ width:"50%" , margin: "0 auto"  }} >
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchQuery}
                
    onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            </Col>
            </Row>
            < Row className="code-container mt-5">
            <div className="table-responsive">
              <Table responsive> 
              <thead>
                <tr className='server-card' style={{ background:'#BAC6F5',  color:'#2B47D6', marginTop:'13px', marginLeft:'69' }}>
          <th style={{ flex: '1' }}>Full Name</th>
          <th style={{ flex: '2' }}>Email</th>
          <th style={{ flex: '1' }}>Role</th>
          <th style={{ flex: '0' }}>Action</th>
        </tr>
      </thead>
      <tbody >
        {teamMembers.filter((member) =>
      member.email.toLowerCase().includes(searchQuery.toLowerCase())
    ).map((member,index) => (
          <tr key={member.id} className='server-card'>
            <td style={{ flex: '1' }}>{member.first_name} {member.last_name}</td>
            <td style={{ flex: '2' }}> {member.email}</td>
            <td style={{ flex: '1' }}> {member.role}</td>
            <td style={{ flex: '0' }}>
              
              <i onClick={() => {
                      const updatedShowDropdown = [...showDropdown];
                      updatedShowDropdown[index] = !updatedShowDropdown[index];
                      setShowDropdown(updatedShowDropdown);
                      setSelectedMember(member);
                     
                      
                    }}>
                           <Icon icon="fe:elipsis-v" style={{ color: "blue", cursor: "pointer" }} />
                           </i>

                       
                    {showDropdown[index] && selectedMember && (

    <Dropdown className='code-dropdown'  isOpen={() => showDropdown[index] && selectedMember } toggle={ () => {
        const updatedShowDropdown = [...showDropdown];
        updatedShowDropdown[index] = !updatedShowDropdown[index];
        setShowDropdown(updatedShowDropdown);
        setSelectedMember(member);
       
        
      }}>
      <DropdownMenu className='drop-menu'>
        <DropdownItem className="dropdown-item" onClick={() => handleEdit(member)}>
          <Icon icon="raphael:edit" />
          Edit
        </DropdownItem>
        <DropdownItem className="dropdown-item" onClick={() => handleRemove(selectedMember)}>
          <Icon icon="ph:trash" />
          Remove
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
                    )}
                    
  
  
                       
                      
                        </td>
          </tr>
        ))}
      </tbody>
      
      </Table>
            </div>
          
            </Row>
            <Row>
              <Col>
               {/* Pagination */}
  <TablePagination
    component="div"
    count={totalPages * 10} // Total number of items in the entire list (total pages * items per page)
    page={currentPage - 1} // Current page index (subtract 1 because TablePagination uses 0-based index)
    onPageChange={(e, newPage) => setCurrentPage(newPage + 1)} // Update current page when pagination changes
    rowsPerPage={10} // Number of items per page
    rowsPerPageOptions={[10]} // Options for number of items per page (you can add more if needed)
  />
              </Col>
            </Row>
          
    </Container>
      <Modal className="model mt-0" isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
      <ModalBody className='model-body mt-0'>
  <form>
  <div className="mb-2">
            <label htmlFor="first_name" className="form-label">First Name</label>
      <Input className="form-control-model"
        type="text"
        id="firstName"
        name="firstName"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
    </div>
    <div className="mb-2">
      <label htmlFor="lastName"  className="form-label">Last Name</label>
      <Input className="form-control-model"
        type="text"
        id="lastName"
        name="lastName"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
    </div>
    <div className="mb-2">
      <label htmlFor="email"  className="form-label">Email</label>
      <Input className="form-control-model"
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
    </div>
    <div className="mb-2">
      <label htmlFor="phone" className="form-label">Phone</label>
      <Input className="form-control-model"
        type="text"
        id="phone"
        name="phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
    </div>
    <div className="mb-2">
<label for="ControlInput" className="form-label">Role</label>
<Input className="select-model" type="select" id="role" name="role"
value={role}
onChange={(e) => setRole(e.target.value)}
>
    <option>admin</option>
    <option> developer</option>
    <option>devops</option>
  
  </Input>
</div>


  </form>
  <ModalFooter className='form-btn'>
            <button className="cancel-button" onClick={toggle} style={{ backgroundColor: "#ccc", color: "black", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }} >Cancel</button>
            <button className="submit-button" onClick={ handleInviteMember} style={{ backgroundColor: "#2B47D6", color: "white", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }}>Submit</button>
          </ModalFooter>
</ModalBody>
</Modal>


<Modal className="model mt-0" isOpen={modalUpdate} toggle={toggleUpdate} unmountOnClose={unmountOnClose}>
  <ModalBody className='model-body mt-0'>
    <Form >
      <div className="mb-2">
        <label htmlFor="firstName" className="form-label">First Name</label>
        <Input className="form-control-model" type="text" id="firstName" name="firstName" value={firstName} onChange={handleInputChange} />
      </div>
      <div className="mb-2">
        <label htmlFor="lastName" className="form-label">Last Name</label>
        <Input className="form-control-model" type="text" id="lastName" name="lastName" value={lastName} onChange={handleInputChange} />
      </div>
      <div className="mb-2">
        <label htmlFor="email" className="form-label">Email</label>
        <Input className="form-control-model" type="email" id="email" name="email" value={email} onChange={handleInputChange} />
      </div>
      <div className="mb-2">
        <label htmlFor="phone" className="form-label">Phone</label>
        <Input className="form-control-model" type="text" id="phone" name="phone" value={phone} onChange={handleInputChange} />
      </div>
      <div className="mb-2">
        <label htmlFor="role" className="form-label">Role</label>
        <Input className="form-control-model" type="select" id="role" name="role" value={role}  onChange={handleInputChange} >
        <option>admin</option>
    <option> developer</option>
    <option>devops</option>
    </Input>
      </div>
      

      <ModalFooter className='form-btn'>
        <button className="cancel-button" onClick={toggleUpdate}style={{ backgroundColor: "#ccc", color: "black", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }} >Cancel</button>
        <button className="submit-button" type="submit" onClick={handleUpdate} style={{ backgroundColor: "#2B47D6", color: "white", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }}>Update Member</button>
      </ModalFooter>
    </Form>
  </ModalBody>
</Modal>

</div>
  );
}
