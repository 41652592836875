import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Table, 
  TableContainer, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper, 
  Button 
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import UpdateIcon from '@mui/icons-material/Update';
import { Alert } from 'antd';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
const Dashboard = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
 console.log("dash begin");
 const [organizations,setOrganizations]=useState([]);
  const [projects, setProjects] = useState([]);
  const [stats, setStats] = useState({
    totalOrganizations: 0,
    successRate: 0,
    mostFrequentUsername: '',
  });
  const [error, setError] = useState(null);
  const [deleted, setDeleted] = useState(false);
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseURL}/api/azure/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setOrganizations(response.data);
        calculateStats(response.data);
      } catch (error) {
        setError('Error fetching organizations data data');
        console.error('Error fetching organizations:', error);
      }
    };

    fetchOrganizations();
  }, [deleted]);

  const calculateStats = (organizations) => {
    const totalOrganizations = organizations.length;
    const successfulOrganizations = organizations.filter((organization) => organization.webhook).length;
    const successRate = (successfulOrganizations / totalOrganizations) * 100;

    const usernameCount = organizations.reduce((acc, organization) => {
      acc[organization.username] = (acc[organization.username] || 0) + 1;
      return acc;
    }, {});
    const mostFrequentUsername = Object.keys(usernameCount).reduce((a, b) =>
      usernameCount[a] > usernameCount[b] ? a : b, '');

    setStats({
      totalOrganizations,
      successRate,
      mostFrequentUsername,
    });
  };

  const StatCard = styled(Card)({
    minWidth: 250,
    height: 100,
    flexBasis: '30%',
    marginBottom: 20,
    borderRadius: 8,
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  });
  const handleDelete= async (id)=>{
    if (window.confirm('Are you sure you want to delete this Organization?')) {
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from local storage

      const response = await axios.delete(baseURL+'/api/azure/'+id+'/',{
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });

      setDeleted(true);

    } catch (error) {
      
      console.error('Error updating organization state :', error);
    }
  }
};
  return (
    <Box sx={{
      marginLeft: '0%',
      marginTop: '50px',
      marginRight: '20px',
      marginBottom: '20px',
      boxShadow: 3,
      borderRadius: 8,
      padding: '20px',
      backgroundColor: '#f5f5f5',
    }}>
      <Typography variant="h3" gutterBottom sx={{ textAlign: 'center', marginBottom: '20px' }}>
        Azure Devops :
      </Typography>
      <hr />
      {error && (
        <Box mb={2}>
          <Alert severity="error" variant="outlined" sx={{ width: '100%', my: 2, borderRadius: 1 }}>
            {error}
          </Alert>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '20px', flexWrap: 'wrap', marginTop: '25px', padding: '0 20px' }}>
      {[
          { title: 'Total Organizations', icon: <AccountTreeIcon /> },
          { title: 'Success webhook Rate', icon: <CheckBoxIcon /> },
          { title: 'Most Frequent Username', icon: <AccountBoxIcon /> },
        ].map((item, index) => (
          <StatCard key={index} sx={{ backgroundColor: 'none' }}>
            <CardContent>
            
              <Typography variant="h5" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                {item.icon && <span style={{ marginRight: '10px' }}>{item.icon}</span>}
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.title === 'Total Organizations' ? stats.totalOrganizations :
                  item.title === 'Success webhook Rate' ? `${stats.successRate.toFixed(2)}%` :
                  stats.mostFrequentUsername}
              </Typography>
            </CardContent>
          </StatCard>
        ))}
      </Box>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', margin: '20px 0' }}>
        Organizations:
      </Typography>
      <Button variant="contained" endIcon={<AddIcon />} component={Link} to="/azure/add" sx={{ float: 'right', marginBottom: '20px' }}>
        Add organization
      </Button>
      <TableContainer component={Paper} sx={{ borderRadius: 8, overflow: 'hidden' }}>
        <Table aria-label="projects table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#9FDDE9' }}>
              <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
              
              <TableCell align="right" style={{ fontWeight: 'bold' }}>Username</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => (
              <TableRow key={organization.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#CFD5D8' } }}>
                <TableCell component="th" scope="row">
                  <Link to={`/azure/${organization.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {organization.name}
                  </Link>
                </TableCell>
                <TableCell align="right">{organization.username}</TableCell>

                <TableCell align="center">
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<UpdateIcon />}
                    component={Link}
                    to={`/azure/${organization.id}/update`}
                    sx={{ marginLeft: '10px' }}
                  >
                    Update
                  </Button>
                <Button
                    variant="contained"
                    color="error"
                    endIcon={<DeleteIcon  />}
                    onClick={() => handleDelete(organization.id)}
                    sx={{ marginLeft: '10px' }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dashboard;
