import React, {  useState,useEffect } from "react";
import '../Styles/Register.css';
import { useNavigate } from 'react-router';
import AuthService from "../services/auth.service";
import { Col, Container, Row } from 'reactstrap';
import { useParams } from "react-router-dom";
function Invitation() {
  const [error, setError] = useState('');
  let { string } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

 const handleInvitation=() => {
  console.log(string);
  AuthService.accept_invitation(string)
  .then((response) => {
    console.log(response.data.user.id);
    localStorage.setItem('id',response.data.user.id);
  })

  .catch((error) => {
    console.log(error);
    if (error.response) {
      setError(error.response.data.message || "Invitation failed");
    } else {
      setError("Invitation failed");
    }
    
  }); 



 };
 handleInvitation();
});
  const handelSetPassword = () => {
    navigate('/invitation_password');
  };

  return (
    <div>
  <Container fluid className="container-register">
       
       <Row className="card-login ">
       <Col lg={6} md={12}  xs={12} className='form' style={{backgroundColor :"white"}} >
           
         <div className="padding align-items-center">
                <h2 className="text-center"></h2>
                <img src="../assets/images/subtract.png" alt="" className=" img2 justify-content-center" />
                <h3 className="text-center mt-4">Accept Invitation:</h3>
                <p className="lead text-center   mt-2 ">
                  Please check your email and follow the instructions to verify your account And Set password .
                  If you did not receive an email or if it has expired, you can resend one.
                </p>

                <div className="mb-2 mb-0 text-center">
                  <button className="btn mb-3 mb-0 text-center" type="button" onClick={handelSetPassword}>
                    Set password
                  </button>
                </div>
                </div>
               
            
            </Col>

            <Col  lg={6} md={6} xs={12} className="d-none d-md-block justify-content-center" style={{background:"#E9EDFC"}}>
              <div className="ser align-items-center">
                <img src="../assets/images/Serops-Logo.png" alt="" className="logo" />
                <img src="../assets/images/Serops-img.png" alt="" className="img-ser" style={{ maxWidth: '80%', height: 'auto' }} />
              </div>
            </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Invitation;
