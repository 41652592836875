import axios from 'axios';
import AuthService from './auth.service';

const BACKEND_HOST =process.env.REACT_APP_BASE_URL+"/api";

const TeamService = {};

TeamService.invit = (data) => {
  return axios.post(BACKEND_HOST + '/organisation/team', data, AuthService.authHeader());
};

TeamService.getAll = () => {
  return axios.get(BACKEND_HOST + '/organisation/team', AuthService.authHeader());
};

TeamService.update = (_id, data) => {
  return axios.put(BACKEND_HOST + '/organisation/' + _id, data, AuthService.authHeader());
};

TeamService.deleteOne = (_id) => {
  return axios.delete(BACKEND_HOST + '/organisation/' + _id, AuthService.authHeader());
};

TeamService.getOne = (_id) => {
  return axios.get(BACKEND_HOST + '/organisation/team/' + _id, AuthService.authHeader());
};

export default TeamService;
