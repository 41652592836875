import React, { useEffect, useState } from "react";
import "../Styles/Sidebar.css";
import {
  FaTh,
  FaBars,
  FaCodeBranch,
  FaProjectDiagram,
  FaUsers,
  FaServer,
  FaBoxes,
  FaConnectdevelop,
  FaTools,
  FaUserCircle,
  FaSignOutAlt,
  FaGithub ,

  FaJenkins ,
} from "react-icons/fa";
import { FaSquareGitlab } from "react-icons/fa6";

import { SiAzuredevops } from "react-icons/si";
import { ImOffice } from "react-icons/im";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { Container } from "reactstrap";
import { useMediaQuery } from '@mui/material';
import AuthService from "../services/auth.service";

const Sidebar = ({ children }) => {
  const isSmallScreen = useMediaQuery("(max-width: 860px)");
  const [isOpen, setIsOpen] = useState(!isSmallScreen); // Sidebar will be open by default for larger screens
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();  
   const [organisation,setOrganisation]=useState(JSON.parse( localStorage.getItem('user')).organisation);
    
  const handleLogout = async () => {
      try {
          
  
          await AuthService.logout();
  
          localStorage.removeItem("token");
          setOrganisation('');
          localStorage.removeItem("user");
  
          navigate('/login');
          window.location.reload();
      } catch (err) {
          if (err.response && err.response.status === 401) {
              console.log("Unauthorized: Logging out failed due to invalid token.");
              localStorage.removeItem("token");
              localStorage.removeItem("user");
              navigate('/login');
          } else {
              console.log("Error during logout:", err.message);
          }
      }
  };
  useEffect(() => {
    setIsOpen(!isSmallScreen);
  }, [isSmallScreen]);


  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/github",
      name: "Github",
      icon: <FaGithub />,
    },
    {
      path: "/gitlab",
      name: "Gitlab",
      icon: <FaSquareGitlab />,
    },
    {
      path: "/jenkins",
      name: "Jenkins",
      icon: <FaJenkins />,
    },
    {
      path: "/azure",
      name: "Azure",
      icon: <SiAzuredevops />,
    },

    {
      path: "/users",
      name: "Users",
      icon: <FaUsers />,
    },

    {
      path: "/api/ia",
      name: " API IA ",
      icon: <FaConnectdevelop />,
    },
  ];
  if (isSmallScreen) {
   menuItem.push(
      {
        path: "/profile",
        name: "Profile",
        icon: <FaUserCircle  />, 
      },
      {
        path: "/login",
        name: "Logout",
        icon: <FaSignOutAlt onClick={handleLogout} />, 
      }
    );
  }
  return (
    <Container className="container">
      <div
        style={{ width: isOpen ? "280px" : "70px"  ,height: isOpen ? "790px" : "650px"  }}
        className="sidebar js-sidebar-scroll"
      >
        <div className="top_section">
          <h1
            style={{ display: isOpen ? "none" : "block" }}
            className="logo-sidebar"
          >
            <FaBars onClick={toggle} />
          </h1>
          <div className="bars mx-auto">
            {isOpen && (
              <img
                src="../assets/images/Serops-Logo.png"
                alt=""
                className="logo-sidebar center mb-3 mt-1"
                onClick={toggle}
              />
            )}
          </div>
          
        </div>
        {isOpen && (
        <section id="sidebar">
        <hr />
      <a href="/users" className="org" style={{ textDecoration: 'none' }}>
        <span className="text" style={{  fontWeight: "bold", color: "#2B47D6", display: "block", textAlign: "center" }}>
            {organisation}</span>
      </a>
      <hr />
    </section>
        )}
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassname="active"
          >
            <div
              className={isOpen ? "none" : "mx-auto"}
              style={{
                display: "flex",
                justifyContent: isOpen ? "flex-start" : "center",
                alignItems: "center",
                fontSize: "24px",
                width: "37px",
                height: "38px",
              }}
            >
              {item.icon}
            </div>

            <div
              style={{
                display: isOpen ? "flex" : "none",
                fontSize: "24px",
              }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>

      <main style={{ marginLeft: isOpen ? "200px" : "75px" ,color:"#ccc" }}>
    {isSmallScreen ? null : <Navbar />} 

        {children}
      </main>
    </Container>
  );
};

export default Sidebar;
