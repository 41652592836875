import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import JenkinsImg from './../../images/jenkins.png';
import successImg from './../../images/success.jpg'; 
import failureImg from './../../images/failed-image.png';
import axios from 'axios';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import Filter9PlusOutlinedIcon from '@mui/icons-material/Filter9PlusOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Button from '@mui/material/Button';
import Solution from '../Solution' ;
import Switch from '@mui/material/Switch';
import { Space,Alert} from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';

import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const PipelineDetails = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [jobs, setJobs] = useState([]);
  const [isSuccess, setIsSuccess] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const { projectId } = useParams();
  const [runActiveState, setRunActiveState] = useState({});
  const handelRun=(e,id)=>{
    e.preventDefault();
  const token = localStorage.getItem('token'); // Retrieve the token from local storage

  const response =  axios.post(baseURL+'/api/jenkins/'+projectId+'/'+id+'/run/', {},{
    headers: {
      Authorization: `Token ${token}`, // Add the token to the request headers
    },
  });
  setRunActiveState((prevState) => ({
    ...prevState,
    [id]: true,
  }));
};
const handleCancel=(e,id)=>{
  e.preventDefault();
const token = localStorage.getItem('token'); // Retrieve the token from local storage

const response =  axios.post(baseURL+'/api/jenkins/'+projectId+'/'+id+'/cancel/', {},{
  headers: {
    Authorization: `Token ${token}`, // Add the token to the request headers
  },
});
setRunActiveState((prevState) => ({
  ...prevState,
  [id]: false,
}));
};
  useEffect(() => {
    const fetchProject = async () => {
      try {
        
        const token = localStorage.getItem('token');
        const response = await axios.get(baseURL+'/api/jenkins/'+projectId+'/jobs', {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching Jobs:', error);
      }
    };

    fetchProject();
  }, [deleted]);
  const formatLogs = (logs) => {
    if (!logs) return "No logs available.";
    const lines = logs.split('\n');
    const first5Lines = lines.slice(0, 5).join('\n');
    const last10Lines = lines.slice(-10).join('\n');
    return `${first5Lines}\n...\n${last10Lines}`;
  };
  const handleCardClick = (job) => {
    setSelectedJob(job === selectedJob ? null : job); // Toggle selected job
  };
  const handle_recommendation = (errors) => {
    localStorage.setItem('errors',errors);
  };
  const OnSwitchChange= async (job,value)=>{
    const jenkins_url="https://dee3-34-38-19-24.ngrok-free.app/";
    const projectData = {
      
      job_name: job.job_name,
      
    };
    if (value===true){
    try {
      
      const token = localStorage.getItem('token'); // Retrieve the token from local storage

      const response = await axios.post(baseURL+'/api/jenkins/'+projectId+'/webhook-set/', projectData,{
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
            // Update the project's webhook state locally
            setJobs((prevJobs) =>
              prevJobs.map((p) =>
                p.id === job.id ? { ...p, webhook: value } : p
              )
            );
    } catch (error) {
      setIsSuccess(false)
      console.error('Error updating webhook state :', error);
    }}else{
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        const response = await axios.post(baseURL+'/api/jenkins/'+projectId+'/webhook-del/', projectData,{
          headers: {
            Authorization: `Token ${token}`, // Add the token to the request headers
          },
        });
        setIsSuccess(true)
              // Update the project's webhook state locally
      setJobs((prevJobs) =>
        prevJobs.map((p) =>
          p.id === job.id ? { ...p, webhook: value } : p
        )
      );
      } catch (error) {
        setIsSuccess(false)
        console.error('Error deleting Pipeline :', error);}

    }}
    const handleDelete= async ()=>{
      if (window.confirm('Are you sure you want to delete this Pipeline?')) {
        const pipelineData = {
          
          job_name: selectedJob.job_name,
          
        };
        
      try {
        
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
  
        const response = await axios.delete(baseURL+'/api/jenkins/'+projectId+'/'+selectedJob.id,{
          headers: {
            Authorization: `Token ${token}`, 
          
          },
        });

        setDeleted(true);

      } catch (error) {
        
        console.error('Error deleting  pipeline  :', error);
      }
    }
  };
  return (
    <div style={{ marginLeft: '0%', marginTop: "120px" }}>
      <h2  >Pipelines  :</h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '80%',
      }}
    >
         <Alert
        message="Success "
        description="Github Project successfully updated."
        type="success"
        showIcon
        closable
      />
      </Space>)}
      <hr style={{ marginTop: " 10% ",width:'50%',maxWidth:'50%',margin: 'auto'}}></hr>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '30px', marginTop: '50px', grid: 'initial', flexWrap: 'wrap' }}>
        {jobs.map((job) => (
          <Card
            sx={{ maxWidth: 345, marginBottom: 2 ,minWidth:200}}
            key={job.id}
            onClick={() => handleCardClick(job)}
            style={{ cursor: 'pointer', backgroundColor: 'inherit' }}
          >
            <CardActionArea>
              <img
                className="img"
                height="190"
                src={job.status === 'SUCCESS' ? successImg : job.status === 'FAILURE' ? failureImg : JenkinsImg}
                alt={job.job_name}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div"  style={{ display: 'flex', alignItems: 'center' }}>
                  {<AssignmentOutlinedIcon style={{ marginRight: '5px' }}/> } { job.job_name }
                </Typography>
                <Typography variant="body2" color="text.secondary"  style={{ display: 'flex', alignItems: 'center' }}>
                {<Filter9PlusOutlinedIcon style={{ marginRight: '5px' }}/> } {job.build_number}

                </Typography>
                <Typography variant="body2" color="text.secondary"  style={{ display: 'flex', alignItems: 'center' }}>
                {<PersonOutlineOutlinedIcon style={{ marginRight: '5px' }}/> } {job.owner }

                </Typography>   
                <Typography variant="body2" color="text.secondary"  style={{ display: 'flex', alignItems: 'center' }}>
                {<FeedbackOutlinedIcon style={{ marginRight: '5px' }}/> } {job.status || "No description available."}

                </Typography>
                <Typography variant="body2" color="text.secondary" style={{ display: 'flex', alignItems: 'center' }}>
  <AccessTimeOutlinedIcon style={{ marginRight: '5px' }} /> {job.time || "No description available."}
</Typography>
<Typography variant="body2" color="text.secondary">   
                        <Switch
            checked={job.webhook}
            onChange={() => OnSwitchChange(job,!job.webhook)}
            name="webhook"
            color="primary"
            title="webhook"
          />

              </Typography>

              <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: 'white', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                   
                   <Stack direction="row" spacing={4}>
                     <Button sx={{ padding: '10px 20px', fontSize: '12px' ,color:'white'}} variant="outlined" startIcon={<HighlightOffOutlinedIcon />}  onClick={(e)=> handleCancel(e,job.id)}>
                       Cancel
                     </Button>
                     <Button 
                     sx={{       
            padding: '10px 20px',
            fontSize: '12px',
            backgroundColor: runActiveState[job.id]   ? 'green' : 'transparent',
            color:'white',
            '&:hover': {
              backgroundColor:  runActiveState[job.id]  ? 'darkgreen' : 'rgba(0, 0, 0, 0.04)',
            },
          }} variant="outlined" 
          startIcon={<PlayCircleOutlineOutlinedIcon />} 
           onClick={(e)=>handelRun(e,job.id)}>
                       Run
                     </Button>

                   </Stack>
                                 </Typography>
                {/* Render logs only if the current job is selected */}
                {selectedJob && selectedJob.id === job.id && (
                  
                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                    
    <Stack direction="row" spacing={1}  style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
      <Button variant="outlined" startIcon={<DeleteIcon />}  onClick={() => handleDelete()}>
        Delete
      </Button>
      </Stack>
     

                  <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' }}>
  
                    {formatLogs(job.log )|| "No logs available."}
                    <hr></hr>
                    {job.errors || "No errors or warnings"}
                    
                  </Typography>
                  </Typography>
                )}
                                              {job.errors && (
                                <Typography variant="body2" color="text.secondary" style={{ marginTop: '10px', backgroundColor: '#333', color: '#fff', padding: '10px', borderRadius: '5px', whiteSpace: 'pre-wrap' ,justifyContent:'center'}}>
                                  
                  <Stack direction="row" spacing={7}  style={{alignItems:'center' ,justifyContent: 'center' }}>
         
                    <Button variant="contained" endIcon={<TipsAndUpdatesIcon />} onClick={handle_recommendation(job.errors)}>
                      
                    <Link to={`/api/ia`}style={{ textDecoration: 'none', color: 'inherit' }}>Get Recommendation</Link>
                    </Button>
                  </Stack>
                                </Typography>
                              )}
              </CardContent>
            </CardActionArea>

          </Card>
        ))}
      </div>
    </div>
  );
};

export default PipelineDetails;
