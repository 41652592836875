import React, { useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { Space,Alert} from 'antd';
const UpdateOrganization = ( ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [access_token, setAccess_token] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const { organizationId } = useParams();
  const getOrganizationData = async (token) => {

    try {
      // Add new project
      const response  = await axios.get(baseURL+'/api/azure/'+organizationId+'/', {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setAccess_token(response.data.access_token);
      setName(response.data.name);
      setUsername(response.data.username);
  
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem('token');
  getOrganizationData(token);
  },[]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const organizationData = {
      name: name,
      username: username,
      access_token: access_token,
    };

    try {
      // Add new project
      await axios.put(baseURL+'/api/azure/'+organizationId+'/', organizationData, {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
      clearForm();
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const clearForm = () => {
    setName('');
    setUsername('');
    setAccess_token('');
  };

  return (
    <div style={{ marginLeft: '0px', marginTop: '50px' ,margin:"0 auto", width:"50%"}}>
      <h2>Update Organization: </h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
         <Alert
        message="Success "
        description="Azure Organization successfully updated."
        type="success"
        showIcon
        closable
      />
      </Space>)


      }
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' ,marginTop:"50px"}}>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <TextField
          label="Username "
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <TextField
          label="Access Token "
          variant="outlined"
          value={access_token}
          onChange={(e) => setAccess_token(e.target.value)}
        />
        <div style={{width:'150px',marginTop:"30px"}}>
        <Button type="submit" variant="contained" color="primary" width="50px" >
          Update Project
        </Button>
        </div>
      </form>

    </div>
  );
};

export default UpdateOrganization;
