import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import AuthService from "../services/auth.service";
import ProfileService from "../services/profile.service";
import {
  Card,
  Input,
  Form,
  Row,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "../Styles/Profil.css";
import { Icon } from "@iconify/react";
import { useMediaQuery } from '@mui/material';
import { Space,Alert} from 'antd';

function Profile() {
    const BACKEND_HOST =process.env.REACT_APP_BASE_URL;
    const [image,setImage]=useState(JSON.parse( localStorage.getItem('user')).avatar);
  const isSmallScreen = useMediaQuery("(max-width: 767px)");
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [passwordChanged,setPasswordChanged]=useState(null);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    avatar: "",
  });
  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const toggle = () => setModal(!modal);
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
    new_password_copy: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleSaveButtonClick = () => {
    // Perform validation or additional checks if needed
    ProfileService.update(AuthService.getUser()._id, formData)
      .then((res) => {
        setUser(res.data);
        setIsEditing(false);

window.location.reload();
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  const handleAvatarChange =  (e) => {
    const file = e.target.files[0];

    if (!file) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    formData.append('avatar', file);

    ProfileService.uploadAvatar(formData)
      .then((response) => {
        console.log('Avatar uploaded successfully:', response.data.message);
        // Update user object in localStorage with the new avatar URL
        

       
      
        
      })
      .catch((error) => {
        console.log(formData);
        console.error('Error uploading avatar:', error);
        // Handle error and update UI or state
      });
  };
  

  useEffect(() => {
    ProfileService.getOne(AuthService.getUser()._id)
      .then((res) => {
        setUser(res.data);
        setFormData(res.data);
        
       
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  const handlePassword = async (e) => {
    e.preventDefault();
  
    try {
      await ProfileService.resetPassword(data);
      console.log("Password reset successful.");
      setPasswordChanged(true);
    } catch (error) {
      console.error("Error:", error.response.data.error);
      setPasswordChanged(false);

    } finally {
      toggle(); // Close modal in both success and error cases
    }
  };

  return (
    <div>
         
       {/* Display success message */}
       {passwordChanged && (
        <Space direction="vertical" style={{ width: "40%" }}>
          <Alert
            message="Success"
            description="Password successfully updated."
            type="success"
            showIcon
            closable
          />
        </Space>
      )}

      {/* Display error message */}
      { passwordChanged ===false && (
        <Space direction="vertical" style={{ width: "40%" }}>
          <Alert
            message="Error"
            description="Failed to update password. Please try again."
            type="error"
            showIcon
            closable
          />
        </Space>
      )}
      <Container className=" container-project  mt-5" >
        <Row className="row1 mx-auto  ">
          <Col className="text-start">
            <h1 className="title-project" style={{color : "#2B47D6", textAlign: "center" }}>Profile</h1>
          </Col>

          <Col className="text-end">
            <button  className="btn-add-project" style={{color : "#2B47D6"}} onClick={toggle}>
              <i >
                <Icon icon="fluent:edit-24-filled" />
              </i>
              {isSmallScreen ? "" : "Update Password"}
            </button>
          </Col>
        </Row>

        <Row className="row2 mt-2">
          <Col lg="6" md="6" sm="12" xs="12" className="mx-auto">
            <Card className="card-profil p-5" style={{ borderRadius: "5%" }}>
              <div className="circle-avatar">
                {formData.avatar ? (
                  <div className="image-avatar"> 
          <img
  className="img-pro"
  src={`${BACKEND_HOST}/${formData.avatar}`}
  alt="Profile"
/>
                    <label className="camera-icon" htmlFor="avatar">
                    <input
                      type="file"
                      id="avatar"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleAvatarChange}
                    />
                      <FaCamera/>
                    </label>
                  </div>
                  
                ) : (
                  <div className="default-avatar">
                    <img
                    className="img-pro"
                    src="assets/images/users/avatar-3.jpg"
                    alt="Profile"
                  />
                    <input
                      type="file"
                      id="avatar"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleAvatarChange}
                    />
                     <label className="camera-icon" htmlFor="avatar">
                      <FaCamera />
                    </label>


                  </div>
                )}
              </div>

              <div className="card-body mt-2 w-100 ">
                <Form style={{ marginTop: "5%" }}>
                  <div className="mb-4">
                    <label htmlFor="first_name" className="form-label">
                      First name
                    </label>
                    <Input
                      className="form-control "
                      type="text"
                      id="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="last_name" className="form-label">
                      Last name
                    </label>
                    <Input
                      className="form-control"
                      type="text"
                      id="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <Input
                      className="form-control"
                      type="email"
                      id="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="phone" className="form-label">
                      Phone Number
                    </label>
                    <Input
                      className="form-control"
                      type="number"
                      id="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="d-flex justify-content-end position-relative">
                    {!isEditing && (
                      <button
                        className="btn text-center"
                        type="button"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={handleEditButtonClick}
                      >
                        <Icon icon="fluent:edit-24-filled" />
                        Edit
                      </button>
                    )}
                    {isEditing && (
                      <button
                        className="btn text-center"
                        type="button"
                        onClick={handleSaveButtonClick}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </Form>
              </div>
            </Card>
          </Col>
        </Row>

        <Modal
          className="model mt-0"
          isOpen={modal}
          toggle={toggle}
          unmountOnClose={unmountOnClose}
        >
          <ModalBody className="model-body mt-0">
            <Form>
              <div className="mb-2">
                <label htmlFor="old_password" className="form-label">
                  Old Password
                </label>
                <Input
                  className="form-control"
                  type="password"
                  id="old_password"
                  value={data.old_password}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="new_password" className="form-label">
                  New Password
                </label>
                <Input
                  className="form-control"
                  type="password"
                  id="new_password"
                  value={data.new_password}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-2">
                <label htmlFor="new_password_copy" className="form-label">
                  Repeat Password
                </label>
                <Input
                  className="form-control"
                  type="password"
                  id="new_password_copy"
                  value={data.new_password_copy}
                  onChange={handleChange}
                />
              </div>

              <ModalFooter className="form-btn">
                <button className="cancel-button" onClick={toggle}
                 style={{ backgroundColor: "#ccc", color: "black", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }}>
                  Cancel
                </button>

                <button className="submit-button" onClick={handlePassword}
                 style={{ backgroundColor: "#2B47D6", color: "white", border: "none", padding: "10px 20px", borderRadius: "5px", cursor: "pointer", fontSize: "16px" }}>
                  Submit
                </button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
}

export default Profile;
