import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import AuthService from "../services/auth.service";
import { Dropdown, Nav } from "react-bootstrap";
import ProfileService from "../services/profile.service";
function Navbar({ isOpen, avatar }) {
  const BACKEND_HOST =process.env.REACT_APP_BASE_URL;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [user, setUser] = useState(null);
  const [image, setImage] = useState('');
  const navigate = useNavigate();
  

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    avatar: "",
  });
  useEffect(() => {
    const userData = AuthService.getUser();
    ProfileService.getOne(AuthService.getUser()._id, {})
    .then((res) => {
      console.log("this iis data ",res.data);
      setImage(res.data.avatar);
    
    })
    .catch((err) => {
      console.error("Error:", err);
    });
    let my_user = JSON.parse(localStorage.getItem('user'));
    setImage(my_user.avatar) ;
    
    if (userData) {
      setUser(userData);
      
    }
  }, []);

  const handleLogout = async () => {
    try {
        

        await AuthService.logout();

        localStorage.removeItem("token");
        
        localStorage.removeItem("user");

        navigate('/login');
        
    } catch (err) {
        if (err.response && err.response.status === 401) {
            console.log("Unauthorized: Logging out failed due to invalid token.");
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            navigate('/login');
        } else {
            console.log("Error during logout:", err.message);
        }
    }
};

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDropdownOpen(false);
  };

  const getFullName = () => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    }
    return "";
  };

  return (
    <Nav className="navbar" style={{margin:"0 0-0px auto",width:"25%"}}>
      <Dropdown
        show={dropdownOpen}
        onToggle={toggleDropdown}
        as="li"
      >
        <Dropdown.Toggle
          as={Nav.Link}
          id="userDropdown"
          className="nav-link dropbtn"
        >
          <span className="d-none d-lg-inline account-user-name text-gray-600">
            {getFullName()}

            <img
              className="img-profile rounded-circle"
              src={ image ? `${BACKEND_HOST}${image}` :  "../assets/images/users/avatar-3.jpg"}

              alt="Profile"
              
            />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          <Dropdown.Item onClick={() => navigate("/Profile")}>
            <Icon className="icon" icon="mdi:user" />
            Profile
          </Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>
            <Icon className="icon" icon="ri:logout-box-line" />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
}

export default Navbar;
