import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import {  Space,Alert} from 'antd';
import { useParams } from 'react-router-dom';

const AddProject = ( ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [name_repo, setName_repo] = useState('');
  const { organizationId } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve the token from local storage

    const organizationData = {
      name_repo: name_repo,

    };

    try {
      // Add new project
      await axios.post(baseURL+'/api/azure/'+organizationId+'/projects/', organizationData, {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
      clearForm();
    } catch (error) {
      console.error('Error adding organization:', error);
    }
  };

  const clearForm = () => {
    setName_repo('');

  };

  return (
    <div style={{ marginLeft: '0%', marginTop: '50px' , margin:"0 auto", width:"50%"}}>
      <h2>Add New Project: </h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
         <Alert
        message="Success "
        description="Azure project successfully added."
        type="success"
        showIcon
        closable
      />
      </Space>)


      }
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' ,marginTop:"50px"}}>
        <TextField
          label="Name "
          variant="outlined"
          value={name_repo}
          onChange={(e) => setName_repo(e.target.value)}
          required
        />

        <div style={{width:'150px',marginTop:"30px"}}>
        <Button type="submit" variant="contained" color="primary" width="50px" >
          Add Project 
        </Button>
        </div>
      </form>

    </div>
  );
};

export default AddProject;
