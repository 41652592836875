import React, { useEffect } from "react";
import Sidebar from '../components/Sidebar';

import { Navigate, Outlet } from "react-router-dom";


const Admin = () => {
    return (
      
      <div>
       

       <Sidebar>
          <Outlet />
        </Sidebar>

      </div>
    );
    }
    
  

export default Admin;