import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row, Form, FormGroup, Label, Input, FormText, Button } from 'reactstrap';
import axios from 'axios'; // Import Axios

const Solution = () => {
  const [errors, setErrors] = useState(localStorage.getItem('errors') || ''); // Use plural for clarity
  const [solution, setSolution] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null); // Use a descriptive name
  const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    // Clear errors when the component mounts or errors change
    if (!errors) {
      setApiError(null);
    }
  }, [errors]);

  const fetchData = async () => {
    setIsLoading(true);
    setApiError(null);

    try {
      const response = await axios.post(baseURL+'/ia/ia/', { error: errors }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = response.data;
      const parsedData = JSON.parse(data);
      setSolution(parsedData.solution);
      
      localStorage.setItem('errors', '');
    } catch (err) {
      console.error('Error fetching data:', err);
      setApiError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleErrorsChange = (event) => {
    setErrors(event.target.value);
  };

  const handleButtonClick = () => {
    fetchData();
  };

  return (
    <div style={{ width: '90%', margin: '50px auto' }}>
      <Container fluid className="container-register" style={{ width: '100%' }}>
        <Row className="card-login" style={{backgroundColor:'#f5f5f5',alignItems:'center'}} >
          <Col md={6}>
            <FormGroup>
              <Label for="errorsField" style={{ color: '#2B47D6' }}>
                Errors & Warnings:
              </Label>
              <Input
                type="textarea"
                rows={2}
                id="errorsField"
                value={errors}
                onChange={handleErrorsChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <Button color="primary" onClick={handleButtonClick} disabled={isLoading}>
              {isLoading ? 'Loading...' : 'Get Response'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup hidden={!solution}>
              {apiError ? (
                <FormText style={{ color: 'red' }}>Error: {apiError}</FormText>
              ) : solution ? (
                <>
                  <Row>
                    <Label for="solutionField" style={{ color: '#2B47D6' , marginTop:'30px'}}>
                      IA Recommendation:
                    </Label>
                  </Row>
                  <Row>
                    <span
                      style={{
                        width: '80%',
                        height: '50px',
                        padding: '10px',
                        margin: '10px auto',
                        backgroundColor: '#fff',
                        color: '#000',
                        fontFamily: 'monospace, sans-serif',
                        fontSize: '16px',
                        textAlign: 'left'
                      }}
                    >
                      {solution}
                    </span>
                  </Row>
                </>
              ) : (
                <FormText>No solution available yet.</FormText>
              )}
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Solution;
