import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import {  Space,Alert} from 'antd';
import { useParams,Link ,useNavigate} from 'react-router-dom';

const AddPipeline = ( ) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [job_name, setJob_name] = useState('');
  const { projectId } = useParams();
  const navigate = useNavigate();
  
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token'); // Retrieve the token from local storage
    console.log(job_name)
    const jobData = {
      job_name: job_name

    };

    try {
      // Add new project
      await axios.post(baseURL+'/api/jenkins/'+projectId+'/jobs', jobData, {
        headers: {
          Authorization: `Token ${token}`, // Add the token to the request headers
        },
      });
      setIsSuccess(true)
      clearForm();
      navigate('/jenkins/'+projectId);
    } catch (error) {
      console.error('Error adding Pipeline:', error);
    }
  };

  const clearForm = () => {
    setJob_name('');

  };

  return (
    <div style={{ marginLeft: '0%', marginTop: '50px' , margin:"0 auto", width:"50%"}}>
      <h2>Add New Pipeline: </h2>
      { isSuccess &&(
      <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
    >
         <Alert
        message="Success "
        description="Jenkins Pipeline successfully added."
        type="success"
        showIcon
        closable
      />
      </Space>)


      }
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '20px' ,marginTop:"50px"}}>
        <TextField
          label="Pipeline name:"
          variant="outlined"
          value={job_name}
          onChange={(e) => setJob_name(e.target.value)}
          required
        />


        <div style={{width:'150px',marginTop:"30px"}}>
        <Button type="submit" variant="contained" color="primary" width="50px" >
          Add Pipeline
        </Button>
        </div>
      </form>

    </div>
  );
};

export default AddPipeline;
